import { Component, ElementRef, Input, NgModule } from '@angular/core';
import QRious from 'qrious';

var QRCodeComponent = (function () {
    /**
     * @param {?} elementRef
     */
    function QRCodeComponent(elementRef) {
        this.elementRef = elementRef;
        this.background = 'white';
        this.backgroundAlpha = 1.0;
        this.foreground = 'black';
        this.foregroundAlpha = 1.0;
        this.level = 'L';
        this.mime = 'image/png';
        this.padding = null;
        this.size = 100;
        this.value = '';
        this.canvas = false;
    }
    /**
     * @param {?} changes
     * @return {?}
     */
    QRCodeComponent.prototype.ngOnChanges = function (changes) {
        if ('background' in changes ||
            'backgroundAlpha' in changes ||
            'foreground' in changes ||
            'foregroundAlpha' in changes ||
            'level' in changes ||
            'mime' in changes ||
            'padding' in changes ||
            'size' in changes ||
            'value' in changes ||
            'canvas' in changes) {
            this.generate();
        }
    };
    /**
     * @return {?}
     */
    QRCodeComponent.prototype.generate = function () {
        try {
            var /** @type {?} */ el = this.elementRef.nativeElement;
            el.innerHTML = '';
            var /** @type {?} */ qr = new QRious({
                background: this.background,
                backgroundAlpha: this.backgroundAlpha,
                foreground: this.foreground,
                foregroundAlpha: this.foregroundAlpha,
                level: this.level,
                mime: this.mime,
                padding: this.padding,
                size: this.size,
                value: this.value
            });
            if (this.canvas) {
                el.appendChild(qr.canvas);
            }
            else {
                el.appendChild(qr.image);
            }
        }
        catch (e) {
            console.error("Could not generate QR Code: " + e.message);
        }
    };
    return QRCodeComponent;
}());
QRCodeComponent.decorators = [
    { type: Component, args: [{
                moduleId: 'module.id',
                selector: 'qr-code',
                template: ""
            },] },
];
/**
 * @nocollapse
 */
QRCodeComponent.ctorParameters = function () { return [
    { type: ElementRef, },
]; };
QRCodeComponent.propDecorators = {
    'background': [{ type: Input },],
    'backgroundAlpha': [{ type: Input },],
    'foreground': [{ type: Input },],
    'foregroundAlpha': [{ type: Input },],
    'level': [{ type: Input },],
    'mime': [{ type: Input },],
    'padding': [{ type: Input },],
    'size': [{ type: Input },],
    'value': [{ type: Input },],
    'canvas': [{ type: Input },],
};
var QRCodeModule = (function () {
    function QRCodeModule() {
    }
    return QRCodeModule;
}());
QRCodeModule.decorators = [
    { type: NgModule, args: [{
                exports: [QRCodeComponent],
                declarations: [QRCodeComponent]
            },] },
];
/**
 * @nocollapse
 */
QRCodeModule.ctorParameters = function () { return []; };

/**
 * Generated bundle index. Do not edit.
 */

export { QRCodeComponent, QRCodeModule };
